import "./styles.css";

import { useState } from "react";

import { Splide, SplideSlide } from '@splidejs/react-splide';


function Carousel({images}) {
    const [isActive, setIsActive] = useState(false);

    let items = isActive ? 6 : 3;

    return (
        <div className="carousel-container">
            <Splide 
                options={{
                    perPage: items,
                    rewind: true,
                    drag: true,
                    pagination: true,
                    gap: "1.5em"
                }}
                aria-label="My Favorite Images">

                {images.map((img) => {
                    return (
                        <SplideSlide>
                            <img src={img} alt="Image 1" />
                        </SplideSlide>
                    );
                })}
            </Splide>

            <button className="btn carousel-btn" onClick={() => setIsActive(!isActive)}>{isActive ? "Меньше" : "Ещё"}</button>
        </div>
    );
}

export default Carousel;