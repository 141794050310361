import './styles.css';

import photo from './../../img/footer.jpg';


function Contacts() {
    return (
        <div className='contacts-container'>
            <div style={{ position: "absolute", zIndex: "-1", justifyContent: "center", width: "100%", display: "flex" }}>
                <img src={photo} className='bg-img' />
                <div style={{ position: "absolute", backgroundColor: "rgba(0, 0, 0, 0.3)", width: "100%", height: "100%" }}></div>
            </div>
            
            <div style={{ height: "100%" }}>
                <div className='container contacts'>
                    <div className='contacts-content'>
                        <div style={{ fontSize: "5em", fontWeight: "bold" }}>
                            ПОЯВИЛИСЬ<br></br>ВОПРОСЫ?
                        </div>

                        <div style={{ fontSize: "3em", fontWeight: "bold" }}>
                            Оставьте контакты и<br></br>мы свяжемся с вами!
                        </div>
                    </div>

                    <div>
                        <form className='contacts-form'>
                            <input type='text' placeholder='Имя' />
                            <input type='tel' placeholder='Телефон' />
                            <textarea type='text' placeholder='Сообщение' />
                            <button className='btn'>Отправить сообщение</button>
                            <div>
                                Нажимая на кнопку, я соглашаюсь на&nbsp;
                                <a href='#' className='link'>обработку персональных данных</a>&nbsp;
                                и с&nbsp;
                                <a href='#' className='link'>правилами пользования Платформой</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacts;