import { useEffect } from "react";

import { useState } from "react";

import s1 from "../img/about/100 лет министерство спорта РФ.png";
import s2 from "../img/about/3 стрелы и звезды.png";
import s3 from "../img/about/Лукойл.png";

import photo from "../img/about/photo.jpg";
import p1 from "../img/about/Дизайн без названия 1.png";
import p2 from "../img/about/Дизайн без названия (1) 1.png";
import Navbar from "../components/navbar/Navbar";


function Sponsors() {
    useEffect(() => {
        document.title = "Наши спонсоры";
    }, [])

    return (
        <>
            <div style={{ backgroundImage: `url(${photo})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                    <div style={{ display: "grid", gridTemplateRows: "auto 1fr 3fr" }}>
                        <Navbar/>

                        <div style={{ height: "7.5em" }}></div>

                        <div style={{ display: "flex", flexDirection: "column", gap: "3em", alignItems: "center", padding: "3em 0" }}>
                            <h1 style={{ fontSize: "4em", color: "#FFFFFF", textAlign: "center", fontWeight: "normal" }}>
                                Федерация спортивной акробатики<br></br>
                                Республики Адыгея
                            </h1>
                            <p style={{ color: "#FFFFFF", fontSize: "1.5em", textAlign: "center" }}>
                                С 2022 года команда единомышленников, фанатов своего дела работает<br></br>
                                над популяризацией спортивной акробатики в Республике Адыгея.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <main>
                <div className="container d-flex">
                    <div><h1>Спонсоры</h1></div>
                    <div style={{ maxWidth: "75%", minWidth: "50%", margin: "0 auto" }}>
                        <div className="flex-row" style={{ justifyContent: "space-around" }}>
                            <div>
                                <img src={s1} style={{ maxWidth: "100%", width: "10em" }} />
                            </div>
                            <div>
                                <img src={s2} style={{ maxWidth: "100%", width: "10em" }} />
                            </div>
                            <div>
                                <img src={s3} style={{ maxWidth: "100%", width: "10em" }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container d-flex">
                    <div><h1>Республиканские соревнования  «Золотые стрелы» 2023</h1></div>

                    <p>
                        <ul style={{ paddingLeft: "2em" }}>
                            <li style={{ marginBottom: "1em" }}>Прошли в ауле Тахтамукай, МФОК «Нарт» 20-21 мая 2023 г.</li>
                            <li style={{ marginBottom: "1em" }}>20 мая 2023 года Федерация отмечала свой первый день рождения!</li>
                            <li style={{ marginBottom: "1em" }}>
                                На праздник спорта собрались делегации: г. Майкоп, г. Краснодар, г. Курганинск, г. Тимашевск,
                                г. Темрюк, Тахтамукайский район, ст. Северская, ст. Каневская, ст. Динская, с. Львовское
                            </li>
                            <li>Более 400 участников соревновались по программам 3-юн - 1-спортивного разряда</li>
                        </ul>
                    </p>

                    <div><img src={p1} /></div>
                </div>

                <div className="container d-flex">
                    <div><h1>Республиканские соревнования  «Мост Дружбы» 2023</h1></div>

                    <p>
                        <ul style={{ paddingLeft: "2em" }}>
                            <li style={{ marginBottom: "1em" }}>
                                25-26 ноября 2023г прошли в а. Тахтамукай (МФОК «Нарт»).
                            </li>
                            <li style={{ marginBottom: "1em" }}>
                                Соревнования стали итоговым мероприятием в части реализации Республиканского гранта
                                «Акробаты - прыжок в будущее» на приобретение гимнастического ковра, на котором
                                встретились спортсмены Адыгеи и Краснодарского края.
                            </li>
                            <li>480 участников боролись за звание лучших в различных дисциплинах от 3 юношеского до 1 спортивного разряда.</li>
                        </ul>
                    </p>

                    <div><img src={p2} /></div>
                </div>

                <div className="container d-flex">
                    <div><h1>Республиканские соревнования  «Золотые стрелы» 2023 </h1></div>

                    <p>
                        Охват соревнований порядка 500 участников из Республики Адыгея и Краснодарского края.
                        Нашими постоянными гостями являются команды г. Краснодара, г. Курганинска, г. Тимашевска,
                        г. Темрюка, г. Геленджика, ст. Северской, ст. Каневской, ст. Динской, с. Львовского,
                        а также в этом году мы планируем сотрудничать с командами из г. Армавира, пригласили к
                        участию команду Ставропольского края, г. Ростова-на-Дону.
                    </p>

                    <p>
                        Пропускная способность спортивного комплекса более 1500 зрителей.
                    </p>

                    <p>
                        На параде открытия присутствие почетных гостей – представителей администрации,
                        депутатов, руководителей творческих коллективов.
                    </p>

                    <p>
                        Мы размещаем логотипы спонсоров на нашей наградной и печатной продукции – грамотах,
                        благодарственных письмах, виндерах, банерах.
                    </p>

                    <p>
                        Нас снимает телевидение, мы приглашаем съемочную группу для монтажа отчетного ролика с соревнований.
                    </p>

                    <p>
                        Мы можем разместить выставочные стенды или продукцию на соревновательной площадке, предлагаемую вашей организацией.
                    </p>
                </div>
            </main>
        </>
    );
}

export default Sponsors;