import "./styles.css";

import Contacts from "../contacts/Contacts";

import logo from "../../img/logo.svg";

import phoneImg from "../../img/icons/telephone.png";
import whatsappImg from "../../img/icons/whatsapp.png";
import emailImg from "../../img/icons/mail.png";
import instImg from "../../img/icons/instagram.png";
import vkImg from "../../img/icons/vk-social-network-logo.png";
import tgImg from "../../img/icons/telegram.png";


function Footer() {
    return (
        <div>
            <Contacts />

            <footer className="container">
                <div className="footer-container">
                    <a>
                        <img src={logo} className="logo-img" />
                    </a>

                    <div className="adresses">
                        <div><img src={phoneImg} className="icon" style={{ margin: "auto 0" }}/>&nbsp;+7 (918) 925-55-02</div>
                        <div>ул. Новороссийская 29, Яблоновский</div>
                        <div>ул. Космическая 41/2, Яблоновский</div>
                    </div>

                    <div className="socials">
                        <div className="socials-title">Мы в соцсетях</div>
                        <div className="socials-list">
                            <a href="#" className="link"><img src={instImg} className="icon" /></a>
                            <a href="#" className="link"><img src={vkImg} className="icon" /></a>
                            <a href="#" className="link"><img src={tgImg} className="icon" /></a>
                            <a href="#" className="link"><img src={whatsappImg} className="icon" /></a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;