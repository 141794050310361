import { useEffect } from "react";

import photo from "../img/about/photo.jpg";
import photo2 from "../img/about/photo2.jpg";
import photo3 from "../img/about/photo3.jpg";
import photo4 from "../img/about/photo4.jpg";
import photo5 from "../img/about/photo5.jpg";
import photo6 from "../img/about/photo6.jpg";

import s1 from "../img/about/100 лет министерство спорта РФ.png";
import s2 from "../img/about/3 стрелы и звезды.png";
import s3 from "../img/about/Лукойл.png";

import Navbar from "../components/navbar/Navbar";
import Promo from "../components/promo/Promo";

import { NavLink } from "react-router-dom";


function About() {
    useEffect(() => {
        document.title = "О нас";
    }, [])

    return (
        <>
            <div style={{ backgroundImage: `url(${photo})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                    <div style={{ display: "grid", gridTemplateRows: "auto 1fr 3fr" }}>
                        <Navbar />

                        <div style={{ height: "7.5em" }}></div>

                        <div style={{ display: "flex", flexDirection: "column", gap: "3em", alignItems: "center", padding: "3em 0" }}>
                            <h1 style={{ fontSize: "4em", color: "#FFFFFF", textAlign: "center", fontWeight: "normal" }}>
                                Федерация спортивной акробатики<br></br>
                                Республики Адыгея
                            </h1>
                            <p style={{ color: "#FFFFFF", fontSize: "1.5em", textAlign: "center" }}>
                                С 2022 года команда единомышленников, фанатов своего дела работает<br></br>
                                над популяризацией спортивной акробатики в Республике Адыгея.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <main>
                <div className="container">
                    <div className="card">
                        <div className="card-content">
                            <h1>О нас</h1>

                            <p>
                                <b>РОО "ФСА" РА</b> - является официальным сообществом Федерации
                                спортивной акробатики Республики Адыгея.
                            </p>

                            <p>
                                <b>Мы</b> являемся единственной аккредитованной Федерацией в Республике.
                            </p>

                            <p>
                                <b>Цель Федерации</b> - популяризация спортивной акробатики на территории Республики.
                            </p>

                            <p>
                                Федерация занимается организацией и проведением соревнований, присвоением
                                спортивных разрядов и званий, организует семинары и курсы для спортивных
                                судей. Участвует в формировании сборной команды Республики.
                            </p>

                            <p>
                                Территориально мы находимся в Тахтамукайском районе, п. Яблоновский, а
                                также открываем отделение в Старобжегокае.
                            </p>

                            <p>
                                В наших филиалах функционируют группы для мальчиков и девочек с 3х до 16 лет.
                                Приглашаем вас стать частью нашей команды!
                            </p>

                            <p>
                                Старшие спортсмены занимаются на базе МБУ ДО СШОР №3 МО «Тахтамукайский район»
                                под руководством тренера по спортивной акробатике - <b>Захаровой Ольги Олеговны</b> - главного
                                тренера сборной Республики Адыгея по спортивной акробатике,
                                Президента РОО «ФСА» Республики Адыгея и хореографа <b>Ноговицыны Ольги Владиславовны </b>
                            </p>
                        </div>

                        <div className="card-photo">
                            <img src={photo2} />
                            <img src={photo3} />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="card">
                        <div className="card-content">
                            <h1>Сборная республики</h1>

                            <p>
                                В <b>2023-2024 году</b> копилку спортивных разрядов пополнили ряды:
                            </p>

                            <p>
                                <b>3 спортивный разряд</b>: Миронова Милана, Ливадняя Виктория,
                                Рашковецкая Ангелина, Фурсова Виктория, Маначинская Алена,
                                Цыцора Дарья, Голубок Алина, Мамаева Маргарита, Жирнова Варвара.
                            </p>

                            <p>
                                <b>2 спортивный разряд</b>: Козловских Дарья, Ефимова Елизавета, Хомякова Алина,
                                Красикова Арина, Бочкарева Полина, Гержикова Арина, Плахотина Мария,
                                Шатилова Полина, Никитина Ясмина, Керченская Вероника, Сердюк Дарья.
                            </p>
                        </div>

                        <div className="card-photo">
                            <img src={photo4} />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="card">
                        <div className="card-content">
                            <h1>Соревнования спортсменов сборной</h1>

                            <p>
                                <b>Спортсмены сборной</b> регулярно выезжают на соревнования и представляют Республику за ее пределами.
                            </p>

                            <p>
                                В <b>2023-2024 году</b> наша команда приняла участие:
                            </p>

                            <p>
                                <b>2 спортивный разряд</b>: Козловских Дарья, Ефимова Елизавета, Хомякова Алина,
                                Красикова Арина, Бочкарева Полина, Гержикова Арина, Плахотина Мария,
                                Шатилова Полина, Никитина Ясмина, Керченская Вероника, Сердюк Дарья.
                            </p>

                            <p>
                                <ul style={{ paddingLeft: "1em" }}>
                                    <li>
                                        <b>25-26 февраля</b> 2023 соревнования по спортивной акробатике
                                        «Вместе ради общего будущего» в МБУ СШ 3 МО «Тахтамукайский район»;
                                    </li>

                                    <li>
                                        <b>1-2 апреля</b> 2023 соревнования по спортивной акробатике МО Динской район;
                                    </li>

                                    <li>
                                        <b>11-12 апреля</b> 2023 соревнования в г. Анапа;
                                    </li>

                                    <li>
                                        <b>30 апреля</b> 2023 соревнования по спортивной акробатике МО Северский район;
                                    </li>

                                    <li>
                                        <b>20-21 мая</b> 2023 организовали и провели Республиканские соревнования «Золотые стрелы»;
                                    </li>

                                    <li>
                                        <b>25-26 ноября</b> 2023 г организовали и провели Республиканские соревнования
                                        по спортивной акробатике «Мост дружбы»;
                                    </li>
                                    <li>
                                        <b>29 февраля-5 марта</b> 2024 г. Всероссийские соревнования
                                        «Две звезды» г. Краснодар по программе КМС (8-15 лет);
                                    </li>

                                    <li>
                                        <b>25-26 марта</b> 2024 Районные соревнования по спортивной акробатике
                                        «Шаг к победе» в г. Тимашевск состоялись;
                                    </li>

                                    <li>
                                        <b>6-7 апреля</b> 2024 г. Первенство МО Динской район;
                                    </li>

                                    <li>
                                        <b>⁠19-21 апреля</b> 2024 г. Первенство МО Северский район;
                                    </li>
                                    <li>
                                        <b>25-26 мая</b> 2024 г. организовали и провели соревнования по спортивной акробатике «Золотые стрелы»
                                    </li>
                                </ul>
                            </p>
                        </div>

                        <div className="card-photo">
                            <img src={photo5} />
                            <img src={photo6} />
                        </div>
                    </div>
                </div>

                <div className="container d-flex">
                    <div><h1>Спонсоры</h1></div>
                    <div style={{ maxWidth: "75%", minWidth: "50%", margin: "0 auto" }}>
                        <div className="flex-row" style={{ justifyContent: "space-around" }}>
                            <div>
                                <img src={s1} style={{ maxWidth: "100%", width: "10em" }} />
                            </div>
                            <div>
                                <img src={s2} style={{ maxWidth: "100%", width: "10em" }} />
                            </div>
                            <div>
                                <img src={s3} style={{ maxWidth: "100%", width: "10em" }} />
                            </div>
                        </div>
                    </div>

                    <div className="container d-flex">
                    <NavLink to="/sponsors" style={{ margin: "0 auto" }}>
                        <button className="btn accordion-btn">
                            Подробнее
                        </button>
                    </NavLink>
                </div>
                </div>
            </main>

            <div>
                <Promo />
            </div>
        </>
    );
}

export default About;