import { useEffect } from "react";

import Header from "../components/header/Header";

import photo from "../img/branches/photo.jpg";


function Branches() {
    useEffect(() => {
        document.title = "Наши отделения";
    }, [])

    return (
        <>
            <Header/>

            <main>
                <div className="container d-flex">
                    <div><h1>Наши отделения</h1></div>
                    <div><img src={photo}/></div>
                    <div>
                        <h2>Краснодарский край, р-а Адыгея</h2>
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "1.5em", fontSize: "1.5em", margin: "1em 0 0 1em" }}>
                            <div><span style={{ marginRight: "1em" }}>&#183;</span>ул. Новороссийская 29, Яблоновский</div>
                            <div><span style={{ marginRight: "1em" }}>&#183;</span>ул. Космическая 41/2, Яблоновский</div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Branches;