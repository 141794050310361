import Navbar from "../navbar/Navbar";

function Header() {
    return (
        <div style={{ backgroundColor: "#4BB34B" }}>
            <Navbar/>
        </div>
    );
}

export default Header;