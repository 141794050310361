import { useEffect } from "react";

import Header from "../components/header/Header";

import Accordion from "../components/accordion/Accordion";

import photo from "../img/events/1.jpg";
import photo2 from "../img/events/2.jpg";
import photo3 from "../img/events/3.jpg";
import photo4 from "../img/events/4.jpg";


var accordions = [
    {
        title: `
        1-й Региональный научно-практический семинар для тренеров и судей
        по виду спорта «Спортивная акробатика» прошел в
        Республике Адыгея (Тахтамукайский район) 14 апреля 2024 года.`,
        content: 
        <>
            <img src={photo} />

            <p>
                Семинар был организован силами Региональной общественной организации
                «Федерации спортивной акробатики»Республики Адыгея, при содействии
                Краснодарской краевой общественной организации "Федерации спортивной акробатики"
                и при поддержке Фонда Президентских Грантов в рамках проекта, реализуемого
                РОО «ФСА» РА «Начало - школа акробатики».
            </p>

            <img src={photo2} />

            <p>
                Приглашенные лекторы:<br></br>
                Горохова Диана Рафкатовна - член Исполкома КК ОО «ФСА»,
                судья Всероссийской категории, руководитель студии спортивного танца «Диана» (ст. Северская);<br></br>
                Новицкая Оксана Викторовна - кандидат педагогических наук,
                старший преподаватель кафедры теории и методики гимнастики КГУФКСТ,
                тренер преподаватель высшей квалификационной категории МБОУ ДО ССШ №1 МО г. Краснодар, судья 1 Категории;<br></br>
                Медведенко Ксения Сергеевна - Президент Федерации спортивной акробатики и
                гимнастических видов спорта «Грация», магистр физической культуры, судья 3 Категории.
            </p>

            <div className="flex-row">
                <img src={photo3} />
                <img src={photo4} />
            </div>
        </>
    },
    {
        title: `
        1-й Региональный научно-практический семинар для тренеров и судей
        по виду спорта «Спортивная акробатика» прошел в
        Республике Адыгея (Тахтамукайский район) 14 апреля 2024 года.`,
        content: 
        <>
            <img src={photo} />

            <p>
                Семинар был организован силами Региональной общественной организации
                «Федерации спортивной акробатики»Республики Адыгея, при содействии
                Краснодарской краевой общественной организации "Федерации спортивной акробатики"
                и при поддержке Фонда Президентских Грантов в рамках проекта, реализуемого
                РОО «ФСА» РА «Начало - школа акробатики».
            </p>

            <img src={photo2} />

            <p>
                Приглашенные лекторы:<br></br>
                Горохова Диана Рафкатовна - член Исполкома КК ОО «ФСА»,
                судья Всероссийской категории, руководитель студии спортивного танца «Диана» (ст. Северская);<br></br>
                Новицкая Оксана Викторовна - кандидат педагогических наук,
                старший преподаватель кафедры теории и методики гимнастики КГУФКСТ,
                тренер преподаватель высшей квалификационной категории МБОУ ДО ССШ №1 МО г. Краснодар, судья 1 Категории;<br></br>
                Медведенко Ксения Сергеевна - Президент Федерации спортивной акробатики и
                гимнастических видов спорта «Грация», магистр физической культуры, судья 3 Категории.
            </p>

            <div className="flex-row">
                <img src={photo3} />
                <img src={photo4} />
            </div>
        </>
    },
]

function Events() {
    useEffect(() => {
        document.title = "Мероприятия";
    }, []);
    

    return (
        <>
            <Header />

            <main>
                <div className="container d-flex">
                    <div><h1>Мероприятия</h1></div>

                    {accordions.map(({title, content}) => (
                        <Accordion title={title} content={content}/>
                    ))}
                </div>
            </main>
        </>
    );
}

export default Events;