import "./styles.css";

import { useState } from "react";

import { NavLink } from "react-router-dom";

import logo from "./../../img/logo.svg";

import useWindowDimensions from "../../hooks/windowDimension";


function Navbar() {
    const normalLink = "nav-link"
    const activeLink = "nav-link nav-link-active"

    const [showMenu, setShowMenu] = useState(false);

    const { windowHeight, windowWidth } = useWindowDimensions();

    return (
        <>
        <nav className='container d-flex' style={{ gap: "2em" }}>
            <div className="nav-container">
                <a className="nav-brand">
                    <img src={logo} className="logo-img" />
                </a>
                {windowWidth > 1500 &&
                    <ul className='nav-list'>
                        {/* <NavLink to="/" className={({ isActive }) => { return isActive ? activeLink : normalLink; }}>Главная</NavLink> */}
                        <NavLink to="/" className={normalLink}>Главная</NavLink>
                        <NavLink to="/about" className={normalLink}>О нас</NavLink>
                        <NavLink to="/branches" className={normalLink}>Наши отделения</NavLink>
                        <NavLink to="/gallery" className={normalLink}>Галерея</NavLink>
                        <NavLink to="/events" className={normalLink}>Мероприятия</NavLink>
                        <NavLink to="/sponsors" className={normalLink}>Наши спонсоры</NavLink>
                    </ul>
                }

                {windowWidth <= 1500 &&
                    <button className="btn menu-btn" onClick={() => setShowMenu(!showMenu)}>
                        <span className="menu-stick"></span>
                        <span className="menu-stick"></span>
                        <span className="menu-stick"></span>
                    </button>
                }
            </div>
        </nav>
        {windowWidth <= 1500 && showMenu &&
            <ul className='menu-list'>
                <NavLink to="/" className={normalLink}>Главная</NavLink>
                <NavLink to="/about" className={normalLink}>О нас</NavLink>
                <NavLink to="/branches" className={normalLink}>Наши отделения</NavLink>
                <NavLink to="/gallery" className={normalLink}>Галерея</NavLink>
                <NavLink to="/events" className={normalLink}>Мероприятия</NavLink>
                <NavLink to="/sponsors" className={normalLink}>Спонсоры</NavLink>
            </ul>
        }
        </>
        
    );
}

export default Navbar;