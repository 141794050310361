import './styles.css';

import photo from "../../img/Промокод.png";


function Promo() {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "3em", margin: "3em 0" }}>
            <img src={photo}/>

            <div className="promo-container">
                <div>
                    <h2>Получите промокод на одну бесплатную тренировку</h2>
                </div>
                <form>
                    <div className="phone-input">
                        <input type="tel" placeholder="Номер телефона" />
                        <button>Получить</button>
                    </div>
                    <div>
                        <div className="custom-checkbox">
                            <input type="checkbox" id="cb" name="cb"/>
                            <label for="cb"></label>
                            <p>
                            Отправляя форму, вы соглашаетесь с&nbsp;<a href="#" className="link">политикой конфиденциальности</a>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Promo;