import { useEffect } from "react";

import Header from "../components/header/Header";

import photo from "../img/gallery/photo.jpg";
import photo2 from "../img/gallery/photo2.jpg";
import Carousel from "../components/carousel/Carousel";

import cphoto from "../img/carousel/1.jpg";
import cphoto2 from "../img/carousel/2.jpg";
import cphoto3 from "../img/carousel/3.jpg";
import cphoto4 from "../img/carousel/4.jpg";
import cphoto5 from "../img/carousel/5.jpg";
import cphoto6 from "../img/carousel/6.jpg";


function Gallery() {
    useEffect(() => {
        document.title = "Галерея";
    }, [])

    return (
        <>
            <Header />
            <main>
                <div className="container d-flex">
                    <div><h1>Галерея</h1></div>
                    <div className="img-block">
                        <img src={photo} />
                        <div>14 апреля в Адыгеи состоялось соревнование по спортивной гимнастике</div>
                    </div>
                </div>

                <div className="container">
                    <Carousel images={[cphoto, cphoto2, cphoto3, cphoto4, cphoto5, cphoto6]} />
                </div>

                <div className="container">
                    <div className="img-block">
                        <img src={photo2} />
                        <div>Во всех филиалах состоялись открытые уроки</div>
                    </div>
                </div>

                <div className="container">
                    <Carousel images={[cphoto4, cphoto5, cphoto6, cphoto, cphoto2, cphoto3]} />
                </div>
            </main>
        </>
    );
}

export default Gallery;