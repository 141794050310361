import './App.css';

import '@splidejs/react-splide/css';

import Main from "./pages/Main";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './components/footer/Footer';
import About from './pages/About';
import Branches from './pages/Branches';
import Gallery from './pages/Gallery';
import Events from './pages/Events';
import Sponsors from './pages/Sponsors';
import ScrollToTop from './hooks/ScrollToTop';


function App() {
    return (
        <>
            <BrowserRouter>
            <ScrollToTop/>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/branches" element={<Branches />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/events" element={<Events />} />
                    <Route path="/sponsors" element={<Sponsors />} />
                </Routes>
                <Footer/>
            </BrowserRouter>
        </>
    );
}

export default App;
