import "./styles.css";



function SendRequestBtn({fontSize}) {
    return (
        <>
            <button className="btn send-req-btn" style={{ fontSize: `${fontSize}` }}>ПОДАТЬ ЗАЯВКУ</button>
        </>
    );
}

export default SendRequestBtn;