import "./styles.css";

import { useState } from "react";


function Accordion({ title, content }) {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="d-flex">
            <p>{title}</p>

            {isActive && content}
            <button
                className="btn accordion-btn"
                onClick={() => setIsActive(!isActive)}>
                {isActive ? "Свернуть" : "Развернуть"}
            </button>
        </div>
    );
}

export default Accordion;