import { useEffect } from "react";

import photo from "../img/main/photo.jpg";
import photo2 from "../img/main/photo2.jpg";
import photo3 from "../img/main/photo3.jpg";

import Navbar from "../components/navbar/Navbar";
import SendRequestBtn from "../components/sendRequestBtn/SendRequestBtn";
import Promo from "../components/promo/Promo";


function Main() {
    useEffect(() => {
        document.title = "Главная";
    }, [])

    return (
        <>
            <div style={{ backgroundImage: `url(${photo})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                    <div style={{ display: "grid", gridTemplateRows: "auto 3fr 2fr" }}>
                        <Navbar />

                        <div style={{ display: "grid", gridTemplateRows: "auto", alignItems: "center" }}>
                            <h1 style={{ fontSize: "4em", color: "#FFFFFF", textAlign: "center", fontWeight: "normal" }}>
                                Федерация спортивной акробатики<br></br>
                                Республики Адыгея
                            </h1>
                        </div>

                        <div style={{ textAlign: "center", padding: "3em 0" }}>
                            <SendRequestBtn fontSize={"3em"} />
                        </div>
                    </div>
                </div>
            </div>

            <main>
                <div className="container">
                    <div className="card">
                        <div className="card-content">
                            <h1>Приглашаем малышей 3+ в группы игровой гимнастики</h1>

                            <p>
                                Игровая гимнастика — выполнение физических упражнений, направленных на
                                развитие гибкости, ловкости, двигательных навыков через легкую, игровую форму.
                            </p>

                            <p>
                                Главное достоинство этого вида гимнастики в наличии игрового момента.
                            </p>

                            <p>
                                Через игру, простые и понятные упражнения малыш тренирует свое тело,
                                поднимает себе настроение.
                            </p>

                            <p>
                                <ul style={{ listStyle: "none" }}>
                                    <span>Цель игровой гимнастики:</span>
                                    <li>✨ развитие всех групп мышц;</li>
                                    <li>✨ улучшение работы внутренних органов и систем;</li>
                                    <li>✨ формирование крепкого иммунитета;</li>
                                    <li>✨ улучшение опорно-двигательного аппарата, осанки;</li>
                                    <li>✨ повышение гибкости и выносливости;</li>
                                    <li>✨ увеличение силы, совершенствование координации, развитие памяти, внимания!</li>
                                </ul>
                            </p>

                            <p>
                                <b>Приглашаем девочек и мальчиков 4-5 лет!</b>
                            </p>

                            <p>
                                Занятия проходят в клубе акробатики <b>AcroStars</b> по адресу Космическая, 41/2 и Новороссийская, 29 (п. Яблоновский).<br></br>
                                Открыто и функционирует бесплатное отделение в Старобжегокае на ул. Ленина 37/1
                            </p>

                            <div style={{ textAlign: "center" }}>
                                <SendRequestBtn fontSize={"1.5em"} />
                            </div>
                        </div>

                        <div className="card-photo">
                            <img src={photo2} />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="card">
                        <div className="card-content">
                            <h1>Приглашаем мальчиков и девочек 4-14 лет в группы акробатики</h1>
                            <p>
                                <ul style={{ listStyle: "none" }}>
                                    <span>Занятия в наших клубах состоят из блоков: </span>
                                    <li>✨ ОФП (развитие силы, выносливости, скорости, ловкости);</li>
                                    <li>✨ СФП (упражнения на гибкость, складки, шпагаты, мосты, базовые элементы акробатики).</li>
                                </ul>
                            </p>

                            <p>
                                Работа в составах (составление композиций, хореография, отработка парно-групповых элементов в составах).
                            </p>

                            <p>
                                Работа ведется на профессиональном спортивном оборудовании - акробатическая дорожка, маты, кубы,
                                мини-батуте, подкидном мостике, стоялках.
                            </p>

                            <p>
                                <b>Приглашаем девочек и мальчиков 6-15 лет!</b><br></br>
                                На занятия спортивной акробатикой в спортивную школу или клуб
                            </p>

                            <p>
                                Занятия проходят в клубе акробатики <b>AcroStars</b> по адресу Космическая, 41/2 и Новороссийская,
                                29 (п. Яблоновский), и в спортивной школе ул. Пушкина 26/1
                            </p>

                            <div style={{ textAlign: "center" }}>
                                <SendRequestBtn fontSize={"1.5em"} />
                            </div>
                        </div>

                        <div className="card-photo">
                            <img src={photo3} />
                        </div>
                    </div>
                </div>
            </main>

            <div>
                <Promo />
            </div>
        </>
    );
}

export default Main;